import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { API_URL, getOrder, setOrder } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Input from "../components/form/Input"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"
import {
  Main,
  MainContainer,
  Grid,
  ButtonContainer,
} from "../components/content/Billing/styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Address = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({
    same: false,
  })
  const [msg, setMsg] = useState({})

  useEffect(() => {
    if (getOrder() && getOrder().billing) {
      let billing = {
        address: "",
        address2: "",
        city: "",
        st: "",
        zip: "",
      }
      const obj = getOrder()
      if (obj.billing) {
        billing.address = obj.billing.address
        billing.address2 = obj.billing.address2
        billing.city = obj.billing.city
        billing.st = obj.billing.st
        billing.zip = obj.billing.zip
      }
      init(billing)
    }
  }, [])

  const init = billing => {
    setNames({
      ...names,
      ...billing,
    })
  }

  const handleUpdate = (name, value) => {
    if (name === "same") {
      setNames(names => ({ ...names, same: !names.same }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/checkoutBilling`)
    let data = new FormData()
    data.append("address", names.address || "")
    data.append("address2", names.address2 || "")
    data.append("city", names.city || "")
    data.append("st", names.st || "")
    data.append("zip", names.zip || "")
    data.append("same", names.same ? "1" : "0")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        let order = {}
        let billing = {
          address: json.address,
          address2: json.address2,
          city: json.city,
          st: json.st,
          zip: json.zip,
        }
        if (getOrder() && getOrder().billing) {
          order = getOrder()
        }
        order.billing = billing
        setOrder(order)
        navigate(json.next)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet
        name="Billing Address"
        description="Enter the billing address for your order."
        slug="billing"
      />
      <Header />
      <PageHdr title="Billing Address" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>Enter the billing address for this order.</h2>
              <form method="post" onSubmit={e => handleSubmit(e)}>
                <Grid>
                  <p>Please enter your billing address.</p>
                  <p></p>
                  <div className="fields">
                    <div className="billing">
                      <div>
                        <Input
                          type="text"
                          label="Address"
                          req={true}
                          name="address"
                          value={names.address}
                          update={handleUpdate}
                          errors={errors}
                        />
                        <Input
                          type="text"
                          label="Address 2"
                          req={false}
                          name="address2"
                          value={names.address2}
                          update={handleUpdate}
                          errors={errors}
                        />
                      </div>
                      <div className="three top-margin">
                        <Input
                          type="text"
                          label="City"
                          req={true}
                          name="city"
                          value={names.city}
                          update={handleUpdate}
                          errors={errors}
                        />
                        <Input
                          type="text"
                          label="State"
                          req={true}
                          name="st"
                          value={names.st}
                          update={handleUpdate}
                          errors={errors}
                        />
                        <Input
                          type="text"
                          label="Zip"
                          req={true}
                          name="zip"
                          value={names.zip}
                          update={handleUpdate}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit">
                    {msg.type && <Msg data={msg} />}

                    <ButtonContainer
                      className={msg.type === "working" ? "hidden" : ""}
                    >
                      <Submit
                        name="Continue to Payment"
                        icon={faChevronCircleRight}
                      />
                    </ButtonContainer>
                  </div>
                </Grid>
              </form>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Address
